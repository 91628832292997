<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.keywords" placeholder="名称/手机/邮箱/社交账号" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="admin_user.shop_id">
					<el-cascader style="width: 100%;" placeholder="请选择所属部门" v-model="params.departments" :options="tree_departments" :props="{expandTrigger: 'hover', value: 'unique_id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item v-else>
					<el-select v-model="params.shop_id" placeholder="城市站点" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.former_id" placeholder="请选择上次业务" filterable  multiple collapse-tags>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.employee_id" placeholder="请选择跟进业务" filterable  multiple collapse-tags>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-cascader style="width: 100%;" placeholder="归属地区" v-model="params.region_id" :options="tree_regions" :props="{checkStrictly: true, expandTrigger: 'hover', value: 'id', label: 'name'}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.type" placeholder="请选择社交类型" style="width: 100%;" filterable multiple collapse-tags>
						<el-option v-for="(type, t) in social_types" :key="t" :label="type" :value="t"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.source" placeholder="请选择来源" style="width: 100%;" filterable multiple collapse-tags>
						<el-option v-for="(source, s) in sources" :key="s" :label="source" :value="+s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.status" placeholder="请选择状态" style="width: 100%;" filterable multiple collapse-tags>
						<el-option v-for="(status, s) in statuses" :key="s" :label="status" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select style="width: 100%;" placeholder="请选择标签" v-model="params.tags" :remote-method="remoteMethod" :loading="tags_loading" :multiple-limit="5" remote multiple filterable default-first-option>
						<el-option v-for="(tag, t) in tags" :key="t" :label="tag" :value="tag"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable>
						<el-option label="创建时间" value="created"></el-option>
						<el-option label="更新时间" value="updated"></el-option>
					</el-select>
					<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getClues({ ...params, page: 1, unbuyer: 0})">查询</el-button>
					<el-button type="primary" @click="getClues({ ...params, page: 1, unbuyer: 1})">待转化(原待分配)</el-button>
					<el-button type="primary" @click="showWeight">分配情况</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" ref="clues_table" class="scroll-wrapper absolute" height="100%" :data="tables" :size="theme.size">
				<el-table-column type="selection" width="42"></el-table-column>
				<el-table-column label="站点" width="100" v-if="!admin_user.shop_id" :formatter="(r) => { return r.shop ? r.shop.name : theme.group; }"></el-table-column>
				<el-table-column label="名称" min-width="120">
					<template slot-scope="scope">
						<el-link type="primary" @click="clue = {...scope.row, tags: scope.row.tags.map(({name}) => { return name; } )}; create_dialog = true;" :disabled="!$utils.update($api.URI_CLUES)">{{scope.row.name}}</el-link>
						<p>{{sources[scope.row.source] || '未知'}}</p>
					</template>
				</el-table-column>
				<el-table-column label="联系方式" min-width="130">
					<template slot-scope="scope">
						<p v-if="scope.row.mobile">手机 {{scope.row.mobile}}</p>
						<p v-if="scope.row.email">邮箱 {{scope.row.email}}</p>
						<p v-if="scope.row.social_account">{{social_types[scope.row.social_type]}} {{scope.row.social_account}}</p>
						<p v-if="scope.row.region">{{scope.row.region.name}}</p>
						<p v-if="scope.row.address">{{scope.row.address}}</p>
						<p>
							<el-tag size="mini" style="margin-right: 10px;" v-for="tag in scope.row.tags" :key="tag.id">{{tag.name}}</el-tag>
						</p>
					</template>
				</el-table-column>
				<el-table-column label="业务专员" width="100">
					<template slot-scope="scope">
						<p style="color: #999; text-decoration: line-through;" v-if="scope.row.former">{{scope.row.former.name}}</p>
						<p v-if="scope.row.employee">{{scope.row.employee.name}}</p>
						<p v-else>系统</p>
						<el-dropdown size="small" @command="updClue">
							<el-tag size="mini" :type="tag_colors[scope.row.status] || '未知'">{{statuses[scope.row.status] || '未知'}}</el-tag>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item v-for="(status, s) in statuses" :key="s" :command="{...scope.row, status: s}">{{status}}</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</template>
				</el-table-column>
				<el-table-column label="客户信息" min-width="160">
					<template slot-scope="scope">
						<template v-if="scope.row.client">
							<p>
								<el-link type="primary" @click="$refs['show_client'].open(scope.row.client.id)">{{scope.row.client.name}}</el-link>
							</p>
							<p v-if="scope.row.client.employee">{{scope.row.client.employee.name}}【{{c_statuses[scope.row.client.status]}}】</p>
							<p v-else>已流入客户公海</p>
						</template>
						<p v-else>未<el-link type="primary">转化</el-link></p>
					</template>
				</el-table-column>
				<el-table-column label="跟进记录" min-width="230">
					<template slot-scope="scope">
						<div v-if="scope.row.clue_follows && scope.row.clue_follows.length" @click="inputFollow(scope.row.clue_follows[0], scope.row.employee_id)">
							<p>{{t_types[scope.row.clue_follows[0].type] || '未知操作'}}于 {{scope.row.clue_follows[0].created_at}}</p>
							<p style="font-size: 12px; color: rgb(153, 153, 153); line-height: 1.2; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{scope.row.clue_follows[0].comment}}</p>
							<p v-if="scope.row.clue_follows.length > 1">等共 {{scope.row.clue_follows.length }} 条跟进</p>
						</div>
						<el-link type="primary" @click="inputFollow({comment: '', clue_id: scope.row.id}, scope.row.employee_id)" v-else>添加</el-link>
					</template>
				</el-table-column>
				<el-table-column label="备注说明" prop="remarks" min-width="180" v-if="theme.width >= 1200"></el-table-column>
				<el-table-column label="操作时间" min-width="200" v-if="theme.width >= 1440">
					<template slot-scope="scope">
						<p v-if="scope.row.updated_at">更新 {{scope.row.updated_at}}</p>
						<p v-if="scope.row.created_at">创建 {{scope.row.created_at}}</p>
					</template>
				</el-table-column>
				<el-table-column width="60">
					<el-dropdown slot="header" @command="batchAction">
						<el-button type="text">操作<i class="el-icon-arrow-down el-icon--right"></i></el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="create" :disabled="!$utils.create('clients')">添加线索</el-dropdown-item>
							<!-- 之前分公司模式下，才能分配线索，防止分配到其它公司 -->
							<el-dropdown-item command="assign" :disabled="!$utils.create('clients')" v-if="admin_user.shop_id">转为客户(原分配线索)</el-dropdown-item>
							<el-dropdown-item command="transfer">分配转移(测试未启用)</el-dropdown-item>
							<el-dropdown-item command="export">导出选中</el-dropdown-item>
							<el-dropdown-item command="delete">删除选中</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<el-button slot-scope="scope" type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete($api.URI_CLUES) || scope.row.deleted_at">删除</el-button>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="theme.is_mobile ? 'total, prev, pager, next' : $config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getClues({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getClues({ ...params, page: v }) }"
				background>
			</el-pagination>
		</main-table>
		<el-dialog width="420px" :title="clue.id ? '编辑线索' : '添加线索'" :visible.sync="create_dialog" :fullscreen="theme.width < 420" :before-close="closeImporting" append-to-body>
			<div v-if="importing">
				<h3>正在导入线索 {{import_index}}/{{import_count}}</h3>
				<el-progress :percentage="Math.round((import_index/import_count)*100)"></el-progress>
			</div>
			<div style="max-height: 64vh; overflow: auto; padding-right: 10px;" class="scroll-wrapper" v-else>
				<el-form ref="create_form" label-width="80px" :model="clue" :rules="clue_rules" :size="theme.size" status-icon>
					<el-form-item label="线索名称" prop="name">
						<el-input v-model="clue.name" autocomplete="off" placeholder="请输入线索名称" clearable>
							<el-select slot="prepend" style="width: 100px;" v-if="!admin_user.shop_id" v-model="clue.shop_id" placeholder="城市站点" filterable clearable>
								<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
							</el-select>
							<el-radio slot="append" v-model="clue.is_client" v-if="!clue.id" label="1">创建客户</el-radio>
						</el-input>
					</el-form-item>
					<el-form-item label="手机号码" prop="mobile">
						<el-input v-model="clue.mobile" autocomplete="off" placeholder="请输入手机号码" clearable></el-input>
					</el-form-item>
					<el-form-item label="电子邮箱" prop="email">
						<el-input v-model="clue.email" autocomplete="off" placeholder="请输入电子邮箱" clearable></el-input>
					</el-form-item>
					<el-form-item label="社交账号" prop="social_account">
						<el-input v-model="clue.social_account" autocomplete="off" placeholder="请输入社交账号" clearable>
							<el-select slot="prepend" style="width: 100px;" v-model="clue.social_type" placeholder="账号类型" filterable clearable>
								<el-option v-for="(type, t) in social_types" :key="t" :label="type" :value="t"></el-option>
							</el-select>
						</el-input>
					</el-form-item>
					<el-form-item label="渠道来源" prop="source">
						<el-select v-model="clue.source" placeholder="请选择渠道来源" style="width: 100%;" :clearable="!clue.id" filterable>
							<el-option v-for="(source, s) in sources" :key="s" :label="source" :value="+s"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="标签类型" prop="region_id">
						<el-select style="width: 100%;" placeholder="请选择标签类型" v-model="clue.tags" :remote-method="remoteMethod" :loading="tags_loading" :multiple-limit="5" remote multiple filterable allow-create default-first-option>
							<el-option v-for="(tag, t) in tags" :key="t" :label="tag" :value="tag"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="所属地区" prop="region_id">
						<el-cascader style="width: 100%;" placeholder="请选择归属地区" v-model="clue.region_id" :options="tree_regions" :props="{checkStrictly: true, expandTrigger: 'hover', value: 'id', label: 'name'}" filterable clearable></el-cascader>
					</el-form-item>
					<el-form-item label="详细地址" prop="address">
						<el-input v-model="clue.address" autocomplete="off" placeholder="请输入详细地址" clearable></el-input>
					</el-form-item>
					<el-form-item label="备注说明" prop="remarks">
						<el-input v-model="clue.remarks" type="textarea" placeholder="请输入备注说明" rows="3" maxlength="255" show-word-limit clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-button slot="label" type="text" @click="downloadTemp" v-if="!clue.id">下载模板</el-button>
						<el-button type="primary" @click="submitForm('create_form')">提交保存</el-button>
						<el-upload style="display: inline-block; margin: 0 10px" accept=".xls,.xlsx" :auto-upload="false" :show-file-list="false" :on-change="importInventories" :action="$api.URI_UPLOADS" :disabled="!$utils.create($api.URI_CLUES)" v-if="!clue.id">
							<el-button type="primary" :disabled="!$utils.create($api.URI_CLUES)" plain>导入线索</el-button>
						</el-upload>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<el-dialog width="360px" title="分配线索" :visible.sync="distribute_dialog" :fullscreen="theme.width < 360" :before-close="closeAssigning" append-to-body>
			<div v-if="assigning && distribute.selection">
				<h3>正在分配线索 {{assign_current}}/{{distribute.selection.length}}</h3>
				<el-progress :percentage="Math.round((assign_current/distribute.selection.length)*100)"></el-progress>
			</div>
			<el-form ref="distribute_form" label-width="80px" :model="distribute" :size="theme.size" v-else status-icon>
				<el-form-item label="分配数量" v-if="distribute.selection">{{distribute.selection.length}} 条线索</el-form-item>
				<el-form-item label="业务专员" prop="employee_id">
					<el-select v-model="distribute.employee_id" placeholder="请选择业务专员" style="width: 100%;" filterable clearable>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('distribute_form')">提交分配</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog width="360px" title="今天线索权重分配情况" :visible.sync="show_weight">
			<el-table style="margin-top: -30px;" size="mini" :data="weights" show-summary>
				<el-table-column label="业务" prop="name" min-width="100"></el-table-column>
				<el-table-column label="计划" prop="clue_weight" min-width="50"></el-table-column>
				<el-table-column label="已分" prop="current" min-width="50"></el-table-column>
				<el-table-column label="待分" prop="total" min-width="50"></el-table-column>
			</el-table>
		</el-dialog>
		<el-dialog width="480px" :title="`转移线索 ${transfer.clues.length} 条`" :visible.sync="transfer_dialog">
			<el-form ref="transfer_form" label-width="80px" :model="transfer" :size="theme.size" status-icon>
				<el-form-item label="目标业务" prop="staff_id" :rules="[{ required: weights.length ? false : true, message: '请选择目标业务专员', trigger: ['blur', 'change'] }]">
					<div>
						<el-select v-model="transfer.staff_id" :placeholder="weights.length ? '不选时根据队列分配转移' : '请选择目标业务专员'" filterable multiple collapse-tags>
							<el-option v-for="staff in employees" :key="staff.id" :label="staff.name" :value="staff.id"></el-option>
						</el-select>
						<el-switch v-model="transfer.is_weight" active-value="1" inactive-value="0" active-text="抵消权重" style="margin-left: 20px;" v-if="transfer.staff_id"></el-switch>
					</div>
					<p v-if="weights.length">{{weights | weightTips}}</p>
				</el-form-item>
				<el-form-item label="更新状态">
					<el-switch v-model="transfer.keep_state" active-value="1" inactive-value="0" active-text="保留状态" inactive-text="待联系"></el-switch>
				</el-form-item>
				<el-form-item label="备注说明" prop="comment">
					<el-input type="textarea" v-model="transfer.comment" placeholder="请输入备注说明" rows="3" maxlength="255" show-word-limit clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('transfer_form')">提交转移</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog width="360px" title="线索跟进" :visible.sync="follow_dialog" :fullscreen="theme.width < 360" append-to-body>
			<el-form ref="follows_form" label-position="top" label-width="80px" size="mini" :model="follow" :rules="follow_rules" status-icon>
				<el-form-item label="备注说明" prop="comment">
					<el-input type="textarea" v-model="follow.comment" placeholder="请输入备注说明" rows="3" maxlength="255" show-word-limit clearable></el-input>
				</el-form-item>
				<el-form-item label="上传附件" prop="attachments">
					<el-upload
						slot="label"
						ref="follows_upload"
						list-type="picture"
						:action="$api.URI_UPLOADS"
						:data="{type: follow_attach_cfg.type}"
						:accept="follow_attach_cfg.ext.map(e => { return '.'+e; }).join(',')"
						:on-success="(r, f, l) => { onSuccess(l); }"
						:on-remove="(f, l) => { follow = {...follow, attachments: l}; }"
						:before-upload="beforeFollowAttach"
						:file-list="follow.attachments"
						:limit="9"
						multiple>
						<el-button type="text" icon="el-icon-upload">上传附件</el-button>
						<p slot="tip" class="el-upload__tip" style="font-size: 12px; line-height: 1.5;">只能上传 {{follow_attach_cfg.ext.join('/').toUpperCase()}} 文件，且单文件大小不超过 {{follow_attach_cfg.format_size_unit}}</p>
					</el-upload>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('follows_form')">提交跟进</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<show-client ref="show_client"></show-client>
	</div>
</template>

<style>
	.mobile {
		display: flex; flex-direction: column; justify-content: space-between; height: 100%;
	}
	.mobile .head {
		display: flex; line-height: 45px; color: #666; font-size: 14px; border-bottom: 1px solid #ccc; padding: 0 10px; justify-content: space-between; background-color: #fff;
	}
	.mobile .wrap {
		flex: 1; background-color: #f5f5f5; overflow: auto;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import XLSX from "xlsx";
	import { ad_export_json_to_excel_multi_sheet } from '@/utils/exportExcel';
	import mainTable from '@/components/main-table';
	import showClient from './clients/show';

	const mobile_reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
	const email_reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

	export default {
		components: {
			showClient,
			mainTable
		},
		computed: {
			...mapState(['theme', 'regions', 'admin_user', 'oa_users', 'oa_departments', 'models']),
			tree_departments () {
				return this.$utils.buildTree(Object.values(this.oa_departments));
			},
			tree_regions () {
				return this.$utils.buildTree(this.regions);
			},
			follow_attach_cfg () {
				return this.$utils.uploadConfig('follows');
			},
			clue_dict () {
				if (!this.models) return {};
				return this.models.clues || {};
			},
			client_dict () {
				if (!this.models) return {};
				return this.models.clients || {};
			},
			follow_dict () {
				if (!this.models) return {};
				return this.models.clue_follows || {};
			},
			social_types () {
				return this.clue_dict.social_types || {};
			},
			sources () {
				return this.clue_dict.sources || [];
			},
			statuses () {
				return this.clue_dict.statuses || {};
			},
			c_statuses () {
				return this.client_dict.statuses || {};
			},
			t_types () {
				return this.follow_dict.types || {};
			},
			tag_colors () {
				return {
					'pending': 'info',
					'uncontact': 'warning',
					'following': 'warning',
					'leaning': 'warning',
					'added': 'success',
					'dealed': 'success',
					'intention': 'success',
					'invalided': 'danger'
				};
			},
			table_temp () {
				const thead = ['名称', '来源ID', '手机', '邮箱', '社交账户', '社交账户类型', '地区ID', '地址', '备注', '标签', '创建客户'];
				const filter = ['name', 'source', 'mobile', 'email', 'social_account', 'social_type', 'region_id', 'address', 'remarks', 'tags', 'is_client'];
				if (!this.admin_user.shop_id) {
					thead.unshift('站点ID');
					filter.unshift('shop_id');
				}
				return {
					data: [],
					sheet: '线索列表',
					thead,
					filter
				};
			}
		},
		filters: {
			weightTips (weights) {
				let total = 0;
				let current = 0;
				for (let weight of weights) {
					total += weight.clue_weight;
					current += weight.clue_weight > weight.current ? (weight.clue_weight - weight.current) : 0;
				}
				return `${weights.length} 人参与权重队列分配。计划 ${total} 条，仍需要 ${current} 条`;
			}
		},
		methods: {
			showWeight () {
				if (!this.weights.length) return this.$message.error('今天暂无分配计划 ');
				this.show_weight = true;
			},
			onSuccess (list) {
				this.follow = {
					...this.follow,
					attachments: list.map((file) => {
						const { response } = file;
						if (response) {
							const { status, id } = response.result;
							if (status == "success") {
								file.id = id;
							}
						}
						return file;
					})
				};
			},
			async remoteMethod (query) {
				if (query !== '') {
					this.tags_loading = true;
					const res = await this.$http.get(this.$api.URI_TAGS, { params: {action: 'search', word: query} });
					this.tags = res.data.result.map((tag) => { return tag.name; });
					this.tags_loading = false;
				} else {
					this.tags = [];
				}
			},
			beforeFollowAttach (file) {
				const { size, format_size_unit } = this.follow_attach_cfg;
				const is = file.size < size;
				if (!is) return this.$message.error('上传跟进附件不能超过 '+format_size_unit);
				return is;
			},
			inputFollow (r, i) {
				this.follow_dialog = true;
				this.follow = { comment: '', clue_id: r.clue_id };
				if (r.created_at && this.$moment().isSame(r.created_at, 'd') && r.employee_id == i) {
					this.follow = { ...r };
				}
			},
			closeImporting (done) {
				if (!this.importing) return done();
				this.$confirm('是否中断导入操作？').then(() => {
					this.importing = false;
					done();
				});
			},
			closeAssigning (done) {
				if (!this.assigning) return done();
				this.$confirm('是否中断分配操作？').then(() => {
					this.assigning = false;
					done();
				});
			},
			closeImported (data) {
				const { thead, filter } = this.table_temp;
				this.importing = false;
				this.create_dialog = false;
				this.import_index = 0;
				this.import_count = 0;
				this.getClues(this.params);
				this.$utils.handleDownload([[...thead, '状态']], [...filter, 'status'], data, "线索导入结果" + this.$moment().format('YYYYMMDDHHmmss'), "导入明细");
			},
			closeAssigned () {
				this.assigning = false;
				this.distribute_dialog = false;
				this.assign_current = 0;
				this.getClues(this.params);
			},
			onDelete (r) {
				this.$confirm(r.name, '确定要删除线索吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_CLUES, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getClues(this.params);
					this.$message.success(msg);
				})
			},
			async getClues (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_CLUES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.tables = result.data;
				this.shops = result.shops;
				this.employees = result.employees;
				this.weights = result.weights.map(w => {
					return {...w, total: w.clue_weight > w.current ? (w.clue_weight - w.current) : 0}
				});
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			updClue (c) {
				// 删除没必要信息
				delete c.clue_follows;
				delete c.employee;
				delete c.regions;
				delete c.shop;
				delete c.tags;
				this.submitClue(c, () => {
					this.message(this.params);
				});
			},
			async submitClue (data, onClose) {
				const res = await this.$http.post(this.$api.URI_CLUES, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose
				});
			},
			async submitFollow (data, onClose) {
				const res = await this.$http.post(this.$api.URI_CLUES_FOLLOWS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose
				})
			},
			async submitClient (data) {
				const res = await this.$http.post(this.$api.URI_CLIENTS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'transfer_form':
							var {  staff_id, is_weight, keep_state, comment, clues: aclues } = this.transfer;
							if (!staff_id) {
								// 当使用权重队列分配时，需要检测分配转移是否大于计划需要分配数量
								// 需要分配数量
								var current = 0;
								for (let weight of this.weights) {
									current += weight.clue_weight > weight.current ? (weight.clue_weight - weight.current) : 0;
								}
								if (!current) return this.$message.error("暂无计划需要使用权重分配");
								if (aclues.length > current) return this.$message.error("当前使用权重分配，转移线索大于计划需要分配数量");
							}
							// this.$message.success("检测通过提交后端");
							this.submitClue({action: 'transfer', staff_id, is_weight, keep_state, comment, clues: aclues.map((c) => { return c.id; })}, () => {
								this.getClues(this.params);
								this.transfer_dialog = false;
							});

						break;

						case 'follows_form':
							this.submitFollow(this.follow, () => {
								this.getClues(this.params);
							});
							this.follow_dialog = false;
							this.follow = {
								clue_id: 0,
								comment: ''
							};
						break;

						case 'create_form':
							if (!this.clue.email && !this.clue.social_account && !this.clue.mobile) return this.$message.error("手机/邮箱/社交账号必填一个");
							this.submitClue(this.clue, () => {
								this.getClues(this.params);
								this.create_dialog = false;
							});
						break;
						
						case 'distribute_form':
							var clues = this.distribute.selection || [];
							if (!clues.length) return this.$message.error("请选择分配线索");
							this.assigning = true;
							for (let c in clues) {
								if (!this.assigning) {
									this.closeAssigned();
									break;
								}
								this.assign_current++;
								if (!clues[c].name) {
									this.$message.error("未知线索名称");
									continue;
								}
								if (!clues[c].mobile && !clues[c].email && !clues[c].social_account) {
									this.$message.error("未知线索联系方式");
									continue;
								}
								await this.submitClient({source_model_type: 'App\\Models\\Clue', source_model_id: clues[c].id, employee_id: this.distribute.employee_id});
							}
							if (this.assign_current === clues.length) {
								setTimeout(() => {
									this.closeAssigned();
								}, 1000);
							}
						break;
					}
				});
			},
			importInventories ({raw}) {
				this.importing = true;
				const fileReader = new FileReader();
				fileReader.readAsBinaryString(raw);
				const social_types = [];
				for (let id in this.social_types) {
					social_types.push(id);
				}
				fileReader.onload = async ({target}) => {
					try {
						const { filter } = this.table_temp;
						const { Sheets, SheetNames } = XLSX.read(target.result, {type: "binary"});
						const data = XLSX.utils.sheet_to_json(Sheets[SheetNames[0]], {header: filter});
						data.shift();
						if (data.length <= 0) {
							this.importing = false;
							return this.$message.error("无需要导入记录！");
						}
						this.import_count = data.length;
						for (var i in data) {
							if (!this.importing) {
								this.closeImported(data);
								break;
							}
							const { name, mobile, email, social_type, social_account, region_id, tags } = data[i];
							this.import_index++;
							if (!name) {
								data[i].status = "提交名称不能为空";
								continue;
							}
							if (!mobile && !email && !social_account) {
								data[i].status = "手机/邮箱/社交账号必填其中一项";
								continue;
							}
							if (social_type && social_types.indexOf(social_type) < 0) {
								data[i].status = "社交账号类型不存在";
								continue;
							}
							// if (mobile && !mobile_reg.test(mobile)) {
							// 	data[i].status = "手机格式不正确";
							// 	continue;
							// }
							// if (email && !email_reg.test(email)) {
							// 	data[i].status = "邮箱格式不正确";
							// 	continue;
							// }
							if (region_id && region_id < 0) {
								data[i].status = "提交地区ID必须大于0，请参考地区Sheets";
								continue;
							}
							const res = await this.$http.post(this.$api.URI_CLUES, {...data[i], tags: tags ? tags.replace("，", ",").split(",") : null});
							const { code, msg } = res.data;
							data[i].status = msg;
							if (code != 0) continue;
						}
						if (this.import_index === this.import_count) {
							setTimeout(() => {
								this.closeImported(data);
							}, 1000);
						}
					} catch (e) {
						console.log(e);
					}
				}
			},
			downloadTemp () {
				const sources = [];
				for (let id in this.sources) {
					sources.push({id, name: this.sources[id]});
				}
				const social_types = [];
				for (let id in this.social_types) {
					social_types.push({id, name: this.social_types[id]});
				}
				const regions = {};
				this.regions.forEach((r) => {
					regions[r.id] = r.name;
					r.parent_name = regions[r.parent_id]; 
				});
				const template = [
					{
						filter: ['id', 'name'],
						thead: ['ID', '名称'],
						sheet: '社交账户类型',
						data: social_types
					}, {
						filter: ['id', 'name'],
						thead: ['ID', '名称'],
						sheet: '来源ID',
						data: sources
					}, {
						filter: ['id', 'name', 'parent_name'],
						thead: ['ID', '名称', '上级'],
						sheet: '地区ID',
						data: this.regions
					}
				];
				if (!this.admin_user.shop_id) {
					template.push({
						filter: ['id', 'name'],
						thead: ['ID', '名称'],
						sheet: '站点ID',
						data: this.shops
					});
				}
				template.unshift(this.table_temp);
				ad_export_json_to_excel_multi_sheet(template, '线索导入模板_生成于' + this.$moment().format('YYYYMMDD'));
			},
			batchAction (m) {
				if (m === 'create') {
					this.create_dialog = true;
					this.clue = {};
					return;
				}
				const { selection } = this.$refs.clues_table;
				if (!selection.length) return this.$message.error('请选择操作内容');
				switch (m) {
					case 'assign':
						for (let clue of selection) {
							if (clue.client) return this.$message.error('选中 '+clue.name+' 线索已分配转为客户！');
						}
						this.distribute_dialog = true;
						this.distribute = { ...this.distribute, selection };
						break;

					case 'transfer':
						this.transfer_dialog = true;
						this.transfer = { clues: selection };
						break;

					case 'export':
							selection.forEach((c) => {
								c.f_source = this.sources[c.source];
								c.f_social_type = this.social_types[c.social_type];
							});
							this.$confirm(`此操作将导出${selection.length}条内容, 是否继续？`, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								var file = "线索导出" + this.$moment().format('YYYYMMDDHHmmss');
								var header = [['ID', '名称', '来源', '手机', '邮箱', '社交账户', '社交账户类型', '备注', '更新时间', '创建时间']];
								var filter = ['id', 'name', 'f_source', 'mobile', 'email', 'social_account', 'f_social_type', 'remarks', 'updated_at', 'created_at'];
								this.$utils.handleDownload(header, filter, selection, file, "线索列表");
							});
						break;
						
					case 'delete':
							this.$confirm(`此操作将永久删除${selection.length}条内容, 是否继续？`, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(async () => {
								let index = 0;
								let notify = this.$notify({
									title: '提示',
									dangerouslyUseHTMLString: true,
									message: '<p>正在准备删除...</p>',
									showClose: false,
									duration: 0
								});
								for (var o in selection) {
									let { id } = selection[o];
									index += 1;
									notify.message = '<p>正在删除 ' + index + ' / ' + selection.length + '</p>';
									await this.$http.get(this.$api.URI_CLUES, {params: {action: "delete", id}, headers: {loading: true}});
								}
								if (index === selection.length) {
									notify.message = '<p>删除完成！</p>';
									setTimeout(() => {
										notify.close();
										this.getClues(this.params);
									}, 3000);
								}
							});
						break;

					default:
					this.$message.error('请选择操作内容');
					break;
				}
			}
		},
		data() {
			const checkEmail = (rule, value, callback) => {
				if (value && !email_reg.test(value)) return callback(new Error('请输入正确的电子邮箱'));
				return callback();
			}
			const checkMobile = (rule, value, callback) => {
				if (value && !mobile_reg.test(value)) return callback(new Error('请输入正确的手机号'));
				return callback();
			}
			return {
				tags: [],
				shops: [],
				tables: [],
				employees: [],
				clue: {},
				follow: {},
				transfer: {
					clues: []
				},
				distribute: {},
				weights: [],
				show_weight: false,
				tags_loading: false,
				create_dialog: false,
				follow_dialog: false,
				transfer_dialog: false,
				distribute_dialog: false,
				importing: false,
				assigning: false,
				import_index: 0,
				import_count: 0,
				assign_current: 0,
				params: {
					perPage: 10,
					keywords: '',
				},
				clue_rules: {
					name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
					email: [{ validator: checkEmail, trigger: ['blur', 'change'] }],
					mobile: [{ validator: checkMobile, trigger: ['blur', 'change'] }],
					source: [{ required: true, message: '请选择来源', trigger: 'change' }]
				},
				follow_rules: {
					comment: [{ required: true, message: '请输入备注说明', trigger: 'blur' }]
				}
			}
		},
		async created () {
			this.getClues(this.params, true);
		}
	};
</script>